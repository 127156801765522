<template>
  <CModal
    :title="$t('sub_options')"
    size="xl"
    color="info"
    :addContentClasses="'modal-offset-1'"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <CRow>
      <CCol col="12" sm="10">
        <CInput
          :label="$t('room_option')"
          :placeholder="$t('room_option')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="modal.data.name"
          plaintext
        />
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="primary" block @click="getRoomOptions()">{{ $t('search') }}</CButton>
      </CCol>
    </CRow>
    <hr>
    <CRow>
      <CCol col="12" sm="10" class="d-flex align-items-center">
        <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
      </CCol>
      <CCol col="12" sm="2">
        <CButton color="info" block @click="() => createRoomOptionModal = { data: { room_id: modal.data.room_id, parent_id: modal.data.id }, modal: true }">{{ $t('create_sub_option') }}</CButton>
      </CCol>
    </CRow>
    <hr>
    <v-client-table :columns="table.columns" :data="table.data" :options="table.options">
      <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
        {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
      </CBadge>
      <div slot="action" slot-scope="props" class="text-center">
        <CButtonGroup>
          <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editRoomOptionModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
          <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteRoomOptionModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
        </CButtonGroup>
      </div>
    </v-client-table>
    <div slot="footer-wrapper"></div>

    <CreateRoomOptionModal :data="createRoomOptionModal.data" :show="createRoomOptionModal.modal" @showUpdate="show => createRoomOptionModal.modal = show" @formSubmit="getRoomOptions()" />

    <EditRoomOptionModal :data="editRoomOptionModal.data" :show="editRoomOptionModal.modal" @showUpdate="show => editRoomOptionModal.modal = show" @formSubmit="getRoomOptions()" />

    <DeleteRoomOptionModal :data="deleteRoomOptionModal.data" :show="deleteRoomOptionModal.modal" @showUpdate="show => deleteRoomOptionModal.modal = show" @formSubmit="getRoomOptions()" />

  </CModal>
</template>

<script>
import CreateRoomOptionModal from '../modal/CreateRoomOptionModal'
import EditRoomOptionModal from '../modal/EditRoomOptionModal'
import DeleteRoomOptionModal from '../modal/DeleteRoomOptionModal'

export default {
  name: 'RoomSubOptionsModal',
  components: {
    CreateRoomOptionModal,
    EditRoomOptionModal,
    DeleteRoomOptionModal,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      table: {
        columns: [ 'name', 'price', 'sort', 'enabled', 'action' ],
        data: [],
        options: {
          headings: {
            name: this.$t('name'),
            price: this.$t('price'),
            sort: this.$t('sort'),
            enabled: this.$t('enable'),
            action: this.$t('action')
          },
          // orderBy: { ascending: true, column: 'sort' },
          sortable: [ 'name', 'price', 'sort', 'enabled' ],
          filterable: [ 'name', 'price' ]
        },
      },
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: {},
      roomSubOptionsModal: { data: {}, modal: false },
      createRoomOptionModal: { data: {}, modal: false },
      editRoomOptionModal: { data: {}, modal: false },
      deleteRoomOptionModal: { data: {}, modal: false },
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.searchData = { room_id: this.modal.data.room_id, parent_id: this.modal.data.id }
        this.getRoomOptions()
      }
    }
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    getRoomOptions () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getRoomOptions', this.$_.clone(this.searchData)).then(res => {
        this.table.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  },
  beforeDestroy: function () {
  },
}
</script>
