<template>
  <CModal
    :title="$t('edit_room')"
    size="xl"
    color="warning"
    :closeOnBackdrop="false"
    :show.sync="modal.show"
    @update:show="showUpdate"
  >
    <h4 class="mb-3">{{ $t('room_information') }}</h4>
    <CRow>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('name')"
          :placeholder="$t('name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.name"
          :isValid="valid_formData_name"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('labels')"
          :placeholder="$t('labels_comma_separated')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="formData.labels"
          :isValid="valid_formData_labels"
        />
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-12">
            {{ $t('introduction') }}
          </label>
          <div class="col-sm-12">
            <textarea-autosize
              :placeholder="$t('introduction')"
              :class="['form-control', valid_formData_introduction ? 'is-valid' : 'is-invalid']"
              v-model="formData.introduction"
              :min-height="100"
              :max-height="350"
            />
          </div>
        </div>
      </CCol>
      <CCol col="12" sm="12">
        <div class="form-group form-row">
          <label class="col-form-label col-sm-12">
            {{ $t('description') }}
          </label>
          <div class="col-sm-12">
            <TinymceEdit editorClass="edit-tinymce-container" :content="formData.description || ''" @contentUpdate="content => formData.description = content" />
          </div>
        </div>
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('sort')"
          :placeholder="$t('sort')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          type="number"
          min="0"
          v-model="formData.sort"
          :isValid="valid_formData_sort"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CSelect
          :label="$t('enable')"
          :options="enabledOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="formData.enabled"
          :isValid="valid_formData_enabled"
        />
      </CCol>
    </CRow>

    <hr>

    <h4 class="mb-3">{{ $t('room_quantity_unit') }}</h4>
    <CRow v-for="(room_quantity_unit, idx) in formData.room_quantity_units" :key="idx">
      <CCol col="12" sm="12">
        <CInput
          :label="$t('quantity_unit_name')"
          :placeholder="$t('quantity_unit_name')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="room_quantity_unit.name"
          :isValid="validString(room_quantity_unit.name)"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('quantity_unit')"
          :placeholder="$t('quantity_unit')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          v-model="room_quantity_unit.measure"
          :isValid="validString(room_quantity_unit.measure)"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('stock')"
          :placeholder="$t('stock')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          type="number"
          min="1"
          v-model="room_quantity_unit.stock"
          :isValid="validNumber(room_quantity_unit.stock)"
        />
      </CCol>
      <CCol col="12" sm="12">
        <CInput
          :label="$t('combo_discount')"
          :placeholder="$t('combo_discount')"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          type="number"
          min="0"
          v-model="room_quantity_unit.combo_discount"
          :isValid="validNumberOrZero(room_quantity_unit.combo_discount)"
        />
      </CCol>
      <!-- <CCol col="12" sm="12">
        <CSelect
          :label="$t('enable')"
          :options="enabledOptions"
          :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
          :value.sync="room_quantity_unit.enabled"
          :isValid="validEnabled(room_quantity_unit.enabled)"
        />
      </CCol> -->
      <CCol col="12" sm="12">
        <h5 class="mb-3">{{ $t('room_price') }}</h5>
        <CRow v-for="(room_price, roomPriceIdx) in room_quantity_unit.room_prices" :key="roomPriceIdx">
          <CCol col="12" sm="12">
            <CInput
              :label="room_price.holiday_type.name"
              :placeholder="$t('price')"
              :horizontal="{ label: 'col-sm-3', input: 'col-sm-9'}"
              type="number"
              min="1"
              v-model="room_price.price"
              :isValid="validNumber(room_price.price)"
            />
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <CButton slot="footer" color="primary" block @click="submit()">{{ $t('save') }}</CButton>
  </CModal>
</template>

<script>
import TinymceEdit from '@/views/tinymce/TinymceEdit'

export default {
  name: 'EditRoomModal',
  components: {
    TinymceEdit,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data () {
    return {
      modal: {
        data: this.data,
        show: this.show
      },
      enabledOptions: [
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      holidayTypeOptions: [],
      formData: {},
    }
  },
  watch: {
    data (e) {
      this.modal.data = e
    },
    show (e) {
      this.modal.show = e
      if (e) {
        this.showRoom()
      }
    }
  },
  computed: {
    valid_formData_name () {
      return this.formData.name ? true : false
    },
    valid_formData_labels () {
      return this.formData.labels ? true : false
    },
    valid_formData_introduction () {
      return this.formData.introduction ? true : false
    },
    valid_formData_description () {
      return this.formData.description ? true : false
    },
    valid_formData_sort () {
      return /^[0-9]+$/.test(this.formData.sort) ? true : false
    },
    valid_formData_enabled () {
      return this.formData.enabled === 0 || this.formData.enabled === 1 ? true : false
    },
    valid_room_quantity_units () {
      return this.formData.room_quantity_units && this.formData.room_quantity_units.length > 0 && this.formData.room_quantity_units.every(e => {
        return e.name && e.measure && e.stock && e.stock > 0 && ((e.combo_discount && e.combo_discount > 0) || e.combo_discount == 0) && (e.enabled === 0 || e.enabled === 1) && e.room_prices && e.room_prices.length > 0 && e.room_prices.every(e => {
          return e.holiday_type_id && e.price && e.price > 0
        })
      })
    },
    valid_submit () {
      return this.valid_formData_name && this.valid_formData_labels && this.valid_formData_introduction && this.valid_formData_description && this.valid_formData_sort && this.valid_formData_enabled && this.valid_room_quantity_units
    }
  },
  mounted: function () {
    this.getHolidayTypes()
  },
  methods: {
    showUpdate (show, e) {
      this.$emit('showUpdate', show, e)
    },
    formSubmit (submit, e) {
      this.$emit('formSubmit', submit, e)
    },
    getHolidayTypes () {
      const loader = this.$loading.show()
      this.$store.dispatch('getHolidayTypes') .then(res => {
        this.holidayTypeOptions = []
        for (const i in res) {
          this.holidayTypeOptions.push({ value: res[i].id, label: res[i].name })
        }
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    showRoom () {
      const loader = this.$loading.show()
      this.$store.dispatch('showRoom', { id: this.modal.data.id }).then(res => {
        this.formData = res[0]
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    },
    submit () {
      if (this.valid_submit) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateRoom', this.$_.clone(this.formData)).then(res => {
          this.formSubmit(true)
          this.modal.show = false
          this.showUpdate(false)
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },

    validString (v) {
      return v ? true : false
    },
    validNumberOrZero (v) {
      return (v && v > 0) || v == 0 ? true : false
    },
    validNumber (v) {
      return v && v > 0 ? true : false
    },
    validEnabled (v) {
      return v === 0 || v === 1 ? true : false
    },
  },
  beforeDestroy: function () {
  },
}
</script>
