<template>
  <CRow>
    <CCol col="12" sm="12">
      <CCard>
        <CCardHeader>
          {{ $route.name }}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12" sm="10"></CCol>
            <CCol col="12" sm="2">
              <CButton color="primary" block @click="getRooms()">{{ $t('search') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <CRow>
            <CCol col="12" sm="10" class="d-flex align-items-center">
              <font class="text-muted">{{ $t('search_info', searchInfo) }}</font>
            </CCol>
            <CCol col="12" sm="2">
              <CButton color="info" block @click="() => createRoomModal = { modal: true }">{{ $t('create_room') }}</CButton>
            </CCol>
          </CRow>
          <hr>
          <v-client-table :columns="columns" :data="data" :options="options">
            <CBadge slot="enabled" slot-scope="props" :color="$_.find(enabledOptions, option => { return option.value === props.row.enabled }).color">
              {{ $_.find(enabledOptions, option => { return option.value === props.row.enabled }).label }}
            </CBadge>
            <font slot="created_at" slot-scope="props">
              {{ $moment(props.row.created_at).format('YYYY-MM-DD HH:mm:ss') }}
            </font>
            <div slot="action" slot-scope="props" class="text-center">
              <CButtonGroup>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => roomImagesModal = { data: props.row, modal: true }">{{ $t('images') }}</CButton>
                <CButton color="info" v-bind="{ variant: 'outline' }" @click="() => roomOptionsModal = { data: props.row, modal: true }">{{ $t('options') }}</CButton>
                <CButton color="warning" v-bind="{ variant: 'outline' }" @click="() => editRoomModal = { data: props.row, modal: true }">{{ $t('edit') }}</CButton>
                <CButton color="danger" v-bind="{ variant: 'outline' }" @click="() => deleteRoomModal = { data: props.row, modal: true }">{{ $t('delete') }}</CButton>
              </CButtonGroup>
            </div>
          </v-client-table>
        </CCardBody>
      </CCard>

      <CreateRoomModal :data="createRoomModal.data" :show="createRoomModal.modal" @showUpdate="show => createRoomModal.modal = show" @formSubmit="getRooms()" />

      <EditRoomModal :data="editRoomModal.data" :show="editRoomModal.modal" @showUpdate="show => editRoomModal.modal = show" @formSubmit="getRooms()" />

      <DeleteRoomModal :data="deleteRoomModal.data" :show="deleteRoomModal.modal" @showUpdate="show => deleteRoomModal.modal = show" @formSubmit="getRooms()" />

      <RoomImagesModal :data="roomImagesModal.data" :show="roomImagesModal.modal" @showUpdate="show => roomImagesModal.modal = show" @formSubmit="getRooms()" />

      <RoomOptionsModal :data="roomOptionsModal.data" :show="roomOptionsModal.modal" @showUpdate="show => roomOptionsModal.modal = show" />

    </CCol>
  </CRow>
</template>

<script>
import CreateRoomModal from './modal/CreateRoomModal'
import EditRoomModal from './modal/EditRoomModal'
import DeleteRoomModal from './modal/DeleteRoomModal'
import RoomImagesModal from './modal/RoomImagesModal'
import RoomOptionsModal from './modal/RoomOptionsModal'

export default {
  name: 'roomsSetting',
  components: {
    CreateRoomModal,
    EditRoomModal,
    DeleteRoomModal,
    RoomImagesModal,
    RoomOptionsModal,
  },
  data () {
    return {
      columns: [ 'name', 'sort', 'enabled', 'created_at', 'action' ],
      data: [],
      options: {
        headings: {
          name: this.$t('name'),
          labels: this.$t('labels'),
          introduction: this.$t('introduction'),
          sort: this.$t('sort'),
          enabled: this.$t('enable'),
          created_at: this.$t('create_time'),
          action: this.$t('action')
        },
        sortable: [ 'name', 'sort', 'enabled', 'created_at', 'enabled' ],
        filterable: [ 'name', 'sort', 'enabled', 'created_at', 'enabled' ]
      },
      enabledOptions: [
        { value: null, label: this.$t('please_select_a') + this.$t('enable') + '...' },
        { value: 1, label: this.$t('enable'), color: 'success' },
        { value: 0, label: this.$t('disable'), color: 'secondary' }
      ],
      searchInfo: { at: '-', count: 0, sec: 0 },
      searchData: {},
      roomImagesModal: { data: {}, modal: false },
      roomOptionsModal: { data: {}, modal: false },
      createRoomModal: { data: {}, modal: false },
      editRoomModal: { data: {}, modal: false },
      deleteRoomModal: { data: {}, modal: false },
    }
  },
  computed: {
  },
  mounted: function () {
    this.getRooms()
  },
  methods: {
    getRooms () {
      const startMoment = this.$moment()
      const loader = this.$loading.show()
      this.$store.dispatch('getRooms', this.$_.clone(this.searchData)).then(res => {
        this.data = res
        this.searchInfo.at = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.searchInfo.count = res.length
        this.searchInfo.sec = this.$moment().diff(startMoment) / 1000
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
